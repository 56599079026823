import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Styled from 'styled-components';
import Constants from './Constants';
import DaysStore from './Stores/DaysStore';
import PhotosStore from './Stores/PhotosStore';

import Home from './Layouts/Home';
import Day from './Layouts/Day';
import ProgressPhotos from './Layouts/ProgressPhotos';
import Photo from './Layouts/Photo';
import AddPhoto from './Layouts/AddPhoto';
import NotFound from './Layouts/NotFound';
import Navigation from './Components/Navigation/Navigation';

const PageWrapper = Styled.div`
    padding: 80px 0 40px;

    @media screen and (min-width: 600px) {
        padding-top: 100px;
    }
`;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: DaysStore.data,
            photos: PhotosStore.data,
            currentPage: 'Daily log'
        };
    }
    componentDidMount() {
        $(window).on(`DaysStore.${Constants.events.storeUpdated}`, this.getDataFromStore.bind(this));
        $(window).on(`PhotosStore.${Constants.events.storeUpdated}`, this.getPhotosFromStore.bind(this));
        DaysStore.getData();
    }
    getDataFromStore() {
        this.setState({
            data: DaysStore.data
        });
    }
    getPhotosFromStore() {
        this.setState({
            photos: PhotosStore.data
        });
    }
    updateCurrentPage(name) {
        this.setState({
            currentPage: name
        });
    }
    render() {
        return (
            <PageWrapper>
                <Router>
                    <Navigation currentPage={this.state.currentPage} />
                    <Switch>
                        <Route exact path='/' render={(props) => <Home {...props} updateCurrentPage={this.updateCurrentPage.bind(this)} store={DaysStore} data={this.state.data} />} />
                        <Route path='/add-day' render={(props) => <Day {...props} updateCurrentPage={this.updateCurrentPage.bind(this)} store={DaysStore} data={this.state.data} />} />
                        <Route path='/day/:id' render={(props) => <Day {...props} updateCurrentPage={this.updateCurrentPage.bind(this)} store={DaysStore} data={this.state.data} />} />
                        <Route path='/progress-photos' render={(props) => <ProgressPhotos {...props} updateCurrentPage={this.updateCurrentPage.bind(this)} store={PhotosStore} data={this.state.photos} />} />
                        <Route path='/photo/:id' render={(props) => <Photo {...props} updateCurrentPage={this.updateCurrentPage.bind(this)} store={PhotosStore} data={this.state.photos} />} />
                        <Route path='/add-photo' render={(props) => <AddPhoto {...props} updateCurrentPage={this.updateCurrentPage.bind(this)} store={PhotosStore} />} />
                        <Route component={NotFound} />
                    </Switch>
                </Router>
            </PageWrapper>
        );
    }
}

ReactDOM.render(<App />, document.getElementById('root'));