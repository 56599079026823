import React from 'react';
import LogList from '../Components/LogList/LogList';
import {Container, Typography} from '@material-ui/core';
import {Add as AddIcon} from '@material-ui/icons';
import FloatingActionButton from '../Components/FloatingActionButton/FloatingActionButton';

class Home extends React.Component {
    componentDidMount() {
        this.props.updateCurrentPage('Daily Log');
    }
    render() {
        return (
            <Container maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom={true}>Your daily log</Typography>
                <LogList history={this.props.history} data={this.props.data} store={this.props.store} />
                <FloatingActionButton to="/add-day" color="primary" label="add" icon={<AddIcon />} />
            </Container>
        );
    }
}

export default Home;