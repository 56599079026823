import React from 'react';
import {IconButton, Typography} from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Styled from 'styled-components';

const VideoWidth = Styled.div`
    margin: 0 auto;
    max-width: ${(props) => props.maxWidth};
    text-align: center;
`;

const VideoWrap = Styled.div`
    width: 100%;
    padding-bottom: ${(props) => props.padding};
    height: 0;
    position: relative;
    margin: 0 0 10px;
`;

const Video = Styled.video`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #ccc;
`;

const ImageTitleBackground = Styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    opacity: 1;
    transition: opacity 150ms ease;

    &.hide {
        opacity: 0;
    }
`;

const ImageTitle = Styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
`;

const Canvas = Styled.canvas`
    display: none;
`;

class Camera extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoWidth: "100%",
            videoRatioPadding: "56.25%",
            currentImageTitle: ""
        };
        navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
        this.interval = 10000;
        this.video = React.createRef();
        this.canvas = React.createRef();
        this.webcamStream = null;
        this.snapshotTimer = null;
        this.shutterClick = new Audio('/shutter-click.mp3');
    }
    componentDidMount() {
        this.startWebcam();
    }
    componentWillUnmount() {
        clearInterval(this.snapshotTimer);
        const stream = this.video.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        this.video.current.srcObject = null;
    }
    startWebcam() {
        if (navigator.getUserMedia) {
            navigator.getUserMedia({
                video: {
                    width: {
                        ideal: 4096
                    },
                    height: {
                        ideal: 2160
                    } 
                },
                audio: false
            }, (localMediaStream) => {
                var videoTrack = localMediaStream.getVideoTracks()[0];
                var trackSettings = videoTrack.getSettings();
                var paddingBottom = trackSettings.height / (trackSettings.width / 100);
                if (this.canvas.current !== null) {
                    this.canvas.current.width = trackSettings.width;
                    this.canvas.current.height = trackSettings.height;
                }
                this.setState({
                    videoWidth: trackSettings.width + 'px',
                    videoRatioPadding: paddingBottom + '%'
                });
                this.webcamStream = localMediaStream;
                if (this.video.current !== null && this.state.videoStream !== false) {
                    this.video.current.srcObject = localMediaStream;
                    this.video.current.play();
                }
            }, function(err) {
                console.log("The following error occured: " + err);
            });
        } else {
            alert("Camera not supported");
        }
    }
    start() {
        let counter = 0;
        const imageNames = ["front", "left", "back", "right", ""];
        this.showText(imageNames[counter]);
        this.snapshotTimer = setInterval(() => {
            this.snapshot(imageNames[counter]);
            counter++;
            if (counter >= (imageNames.length - 1)) clearInterval(this.snapshotTimer);
            this.showText(imageNames[counter]);
        }, this.interval);
    }
    showText(text) {
        this.setState({currentImageTitle: text});
        setTimeout(() => {
            this.setState({currentImageTitle: ""});
        }, (this.interval - 1000));
    }
    snapshot(name) {
        if (this.canvas.current !== null && this.video.current !== null) {
            this.canvas.current.getContext('2d').drawImage(this.video.current, 0,0, this.canvas.current.width, this.canvas.current.height);
            this.shutterClick.play();
            const imageUrl = this.canvas.current.toDataURL("image/jpg");
            this.props.updateImages(name, imageUrl);
        }
    }
    render() {
        const titleBackgroundClass = (this.state.currentImageTitle === "") ? "hide" : "";
        return (
            <VideoWidth maxWidth={this.state.videoWidth}>
                <VideoWrap padding={this.state.videoRatioPadding}>
                    <Video ref={this.video} />
                    <ImageTitleBackground className={titleBackgroundClass}>
                        <Typography variant="h2" component={ImageTitle}>{this.state.currentImageTitle}</Typography>
                    </ImageTitleBackground>
                </VideoWrap>
                <Canvas ref={this.canvas} />
                <IconButton color="primary" aria-label="Take photos" component="span" onClick={this.start.bind(this)}>
                    <PhotoCamera />
                </IconButton>
            </VideoWidth>
        );
    }
}

export default Camera;