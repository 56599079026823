import React from 'react';
import { Link } from 'react-router-dom';
import {SwipeableDrawer, List, ListItem, ListItemIcon, ListItemText, Divider, AppBar, Toolbar, IconButton, Typography} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Styled from 'styled-components';

const ListWrap = Styled.div`
    width: 250px;
`;

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }
    list() {
        return (
            <ListWrap role="presentation" onClick={this.toggleDrawer.bind(this)(false)} onKeyDown={this.toggleDrawer.bind(this)(false)}>
                <List>
                    {['Log', 'Progress photos'].map((text, index) => (
                        <ListItem button key={text}>
                            <ListItemIcon><ListIcon /></ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    {['All mail', 'Trash', 'Spam'].map((text, index) => (
                        <ListItem button key={text}>
                            <ListItemIcon><ListIcon /></ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </ListWrap>
        );
    }
    toggleDrawer(state) {
        return (event) => {
            if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
            this.setState({...this.state, open: state});
        };
    } 
    render() {
        return (
            <div>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.toggleDrawer.bind(this)(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6">{this.props.currentPage}</Typography>
                    </Toolbar>
                </AppBar>
                <SwipeableDrawer
                    anchor="left"
                    open={this.state.open}
                    onClose={this.toggleDrawer.bind(this)(false)}
                    onOpen={this.toggleDrawer.bind(this)(true)}
                >
                    <ListWrap role="presentation" onClick={this.toggleDrawer.bind(this)(false)} onKeyDown={this.toggleDrawer.bind(this)(false)}>
                        <List>
                            <ListItem button component={Link} to="/">
                                <ListItemIcon><ListIcon /></ListItemIcon>
                                <ListItemText primary="Daily log" />
                            </ListItem>
                            <ListItem button component={Link} to="/progress-photos">
                                <ListItemIcon><CameraAltIcon /></ListItemIcon>
                                <ListItemText primary="Progress photos" />
                            </ListItem>
                        </List>
                        <Divider />
                    </ListWrap>
                </SwipeableDrawer>
            </div>
        );
    }
}

export default Navigation;