import React from 'react';
import Styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Fab} from '@material-ui/core';

const FloatingActionWrapper = Styled.div`
    position: fixed;
    bottom: 40px;
    right: 40px;
`;

class FloatingActionButton extends React.Component {
    render() {
        return (
            <FloatingActionWrapper>
                <Link to={this.props.to}>
                    <Fab color={this.props.color} aria-label={this.props.label}>{this.props.icon}</Fab>
                </Link>
            </FloatingActionWrapper>
        );
    }
}

export default FloatingActionButton;