import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Typography} from '@material-ui/core';

class NotFound extends React.Component {
    render() {
        return (
            <Container maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom={true}>Page not found</Typography>
                <Link to="/">
                    <Typography varient="p" component="body1">Homepage</Typography>
                </Link>
            </Container>
        );
    }
}

export default NotFound;