import React from 'react';
import {Link} from 'react-router-dom';
import {ListItem, ListItemText, ListItemSecondaryAction, IconButton} from '@material-ui/core';
import {Delete as DeleteIcon} from '@material-ui/icons';

class LogItem extends React.Component {
    handleClick(e) {
        let url = `/photo/${this.props.id}`;
        if (e.target.classList.contains('icon')) {
            url = `/delete-photo/${this.props.id}`;
        }
        this.props.history.push(url);
    }
    deleteItem(e) {
        e.preventDefault();
        this.props.deleteItem(this.props.id);
    }
    render() {
        const secondaryText = (this.props.weight ? `Weight: ${this.props.weight}kg` : '');
        return (
            <ListItem divider={true} button={true} component={Link} to={`/photo/${this.props.id}`}>
                <ListItemText primary={this.props.date} secondary={secondaryText} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={this.deleteItem.bind(this)}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        );
    }
}

export default LogItem;