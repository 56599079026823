import $ from 'jquery';
import Constants from '../Constants';
import DateFnsUtils from '@date-io/date-fns';

class PhotosStore {
    constructor() {
        this.data = {
            photos: []
        };
        this.dateUtils = new DateFnsUtils();
        this.dateUtils.dateFormat = "dd/mm/YYYY";
        this.getData();
    }
    getData() {
        fetch(Constants.endpoints.photos).then((response) => response.json()).then((data) => {
            this.data = data;
            $(window).trigger(`PhotosStore.${Constants.events.storeUpdated}`);
        });
    }
    getPhoto(id) {
        let photos = this.data.photos.filter((photo) => (photo.id === id));
        if (photos.length <= 0) return false;
        return photos[0];
    }
    updatePhoto(id, data) {
        let formData = new FormData();
        for (let key in data) {
            let value = data[key];
            if (typeof value === 'object' && value.constructor.name === 'Date') {
                value = this.dateUtils.format(new Date(value), "yyyy-LL-dd HH:mm:ss");
            }
            if (key === 'images') continue;
            formData.append(key, value);
        };
        formData.append('id', id);
        return fetch(Constants.endpoints.updatePhoto, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: formData,
        }).then((response) => response.json()).then((data) => {
            //Get latest data
            this.getData();
            // Return success status
            return (data.status === 'ok');
        });
    }
    createPhoto(data) {
        let formData = new FormData();
        for (let key in data) {
            let value = data[key];
            if (typeof value === 'object' && value.constructor.name === 'Date') {
                value = this.dateUtils.format(new Date(value), "yyyy-LL-dd HH:mm:ss");
            }
            if (key === 'images') {
                value.forEach(image => {
                    if (/^data:image\/(png|jpg);base64,/.test(image.url)) {
                        const imageBlob = this.dataURItoBlob(image.url);
                        formData.append('images-' + image.name, imageBlob);
                    }
                });
                continue;
            }
            formData.append(key, value);
        };
        return fetch(Constants.endpoints.createPhoto, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            contentType: 'application/octet-stream',
            body: formData,
        }).then((response) => response.json()).then((data) => {
            //Get latest data
            this.getData();
            // Return success status
            return (data.status === 'ok');
        });
    }
    deletePhoto(id) {
        let formData = new FormData();
        formData.append('id', id);
        return fetch(Constants.endpoints.deletePhoto, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: formData,
        }).then((response) => response.json()).then((data) => {
            //Get latest data
            this.getData();
            // Return success status
            return (data.status === 'ok');
        });
    }
    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        // create a view into the buffer
        var ia = new Uint8Array(ab);
        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
    }
}

const photosStore = new PhotosStore();

export default photosStore;