import React from 'react';
import { Link } from 'react-router-dom';
import {Container, Grid, Typography, FormControl, Button, Input, InputLabel} from '@material-ui/core';
import Styled from 'styled-components';
import Camera from '../Components/Camera/Camera';

const Image = Styled.img`
    width: 100%;
    height: auto;
    margin: 0;
`;

class AddPhoto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photo: {
                date: new Date(),
                images: [],
                weight: ""
            }
        };
    }
    componentDidMount() {
        this.props.updateCurrentPage('Add Photo');
    }
    handleWeightChange(e) {
        let currentState = this.state;
        currentState.photo.weight = e.target.value;
        this.setState(currentState);
    }
    handleSubmit(e) {
        e.preventDefault();
        this.props.store.createPhoto(this.state.photo).then((success) => {
            if (!success) {
                alert('There was a problem saving these photos');
                return;
            }
            this.props.history.push('/progress-photos');
        });
    }
    updateImages(name, url) {
        let newState = this.state;
        newState.photo.images.push({
            "name": name,
            "url": url 
        });
        this.setState(newState);
    }
    render() {
        const cancelLabel = (this.props.match.params.id ? 'Close' : 'Cancel');
        let photo = this.state.photo;
        let images = photo.images.map((image) => (
            <Grid key={image.name} item xs={12} md={6}>
                <Image src={image.url} alt={image.name} />
            </Grid>
        ));
        return (
            <Container maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom={true}>Add Photo</Typography>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl margin="dense" required={false} fullWidth={true}>
                                <InputLabel htmlFor="weight">Weight (kg)</InputLabel>
                                <Input id="weight" type="number" value={photo.weight} onChange={this.handleWeightChange.bind(this)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Camera updateImages={this.updateImages.bind(this)} />
                        </Grid>
                        {images}
                    </Grid>
                    <Grid container spacing={3} justify="flex-end">
                        <Grid item xs={3}>
                            <FormControl margin="normal" fullWidth={true}>
                                <Button variant="contained" color="secondary" component={Link} to="/progress-photos">{cancelLabel}</Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl margin="normal" fullWidth={true}>
                                <Button variant="contained" color="primary" type="submit">Save</Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}

export default AddPhoto;