import React from 'react';
import {List, Typography} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import PhotoItem from './PhotoItem';

class PhotoList extends React.Component {
    constructor(props) {
        super(props);
        this.dateUtils = new DateFnsUtils();
        this.dateUtils.dateFormat = "dd/mm/YYYY";
    }
    deleteItem(id) {
        this.props.store.deletePhoto(id).then((success) => {
            if (!success) {
                alert('There was a problem deleting these photos');
            }
        });
    }
    render() {
        let PhotoItems = this.props.data.photos.map((photo) => {
            const niceDate = this.dateUtils.format(this.dateUtils.date(photo.date), "iii, do LLLL yyyy");
            return (
                <PhotoItem key={photo.id} id={photo.id} date={niceDate} front={photo.front} left={photo.left} back={photo.back} right={photo.right} weight={photo.weight} deleteItem={this.deleteItem.bind(this)} />
            );
        });
        if (Object.keys(this.props.data.photos).length <= 0) {
            PhotoItems = <Typography component="p" varient="body2">You have not logged any Photos</Typography>
        }
        return (
            <List>
                {PhotoItems}
            </List>
        );
    }
}

export default PhotoList;