import React from 'react';
import { Link } from 'react-router-dom';
import {Container, Grid, Typography, FormControl, Button, Input, InputLabel} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ObjectEquals from 'object-equals';
import Styled from 'styled-components';

const Image = Styled.img`
    width: 100%;
    height: auto;
    margin: 0;
`;

class Photo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photo: {
                date: new Date(),
                images: [],
                weight: ""
            }
        };
        if (this.props.data.photos.length > 0 && props.match.params.id) {
            this.state.photo = this.getPhoto(props.data.photos, props.match.params.id);
        }
    }
    componentDidMount() {
        this.props.updateCurrentPage('View Photo');
    }
    componentDidUpdate(oldProps) {
        if (this.props.match.params.id) {
            const newPhoto = this.getPhoto(this.props.data.photos, this.props.match.params.id);
            const oldPhoto = this.getPhoto(oldProps.data.photos, oldProps.match.params.id);
            if (!ObjectEquals(newPhoto, oldPhoto)) {
                this.setState({
                    photo: newPhoto
                });
            }
        }
    }
    getPhoto(photos, id) {
        let matchingPhotos = photos.filter((photo) => (photo.id === id));
        if (matchingPhotos.length <= 0) return false;
        return matchingPhotos[0];
    }
    handleDateChange(date) {
        let currentState = this.state;
        currentState.photo.date = date;
        this.setState(currentState);
    }
    handleWeightChange(e) {
        let currentState = this.state;
        currentState.photo.weight = e.target.value;
        this.setState(currentState);
    }
    handleSubmit(e) {
        e.preventDefault();
        this.props.store.updatePhoto(this.props.match.params.id, this.state.photo).then((success) => {
            if (!success) {
                alert('There was a problem saving these photos');
                return;
            }
            this.props.history.push('/progress-photos');
        });
    }
    render() {
        const cancelLabel = (this.props.match.params.id ? 'Close' : 'Cancel');
        let photo = this.state.photo;
        let images = photo.images.map((image) => (
            <Grid key={image.name} item xs={12} md={6}>
                <Image src={image.url} alt={image.name} />
            </Grid>
        ));
        return (
            <Container maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom={true}>Photo</Typography>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" required={true} fullWidth={true}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="none"
                                        id="date"
                                        label="Date"
                                        format="dd/MM/yyyy"
                                        value={photo.date}
                                        onChange={this.handleDateChange.bind(this)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" required={true} fullWidth={true}>
                                <InputLabel htmlFor="weight">Weight (kg)</InputLabel>
                                <Input id="weight" type="number" value={photo.weight} onChange={this.handleWeightChange.bind(this)} />
                            </FormControl>
                        </Grid>
                        {images}
                    </Grid>
                    <Grid container spacing={3} justify="flex-end">
                        <Grid item xs={3}>
                            <FormControl margin="normal" fullWidth={true}>
                                <Button variant="contained" color="secondary" component={Link} to="/progress-photos">{cancelLabel}</Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl margin="normal" fullWidth={true}>
                                <Button variant="contained" color="primary" type="submit">Save</Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}

export default Photo;