export default {
    "endpoints": {
        "data": "https://daily-log-api.joshcronin.dev/data",
        "updateDay": "https://daily-log-api.joshcronin.dev/update-day",
        "createDay": "https://daily-log-api.joshcronin.dev/create-day",
        "deleteDay": "https://daily-log-api.joshcronin.dev/delete-day",
        "photos": "https://daily-log-api.joshcronin.dev/progress-photos",
        "updatePhoto": "https://daily-log-api.joshcronin.dev/update-photo",
        "createPhoto": "https://daily-log-api.joshcronin.dev/create-photo",
        "deletePhoto": "https://daily-log-api.joshcronin.dev/delete-photo",
    },
    "events": {
        "storeUpdated": "Update.Stores"
    }
};