import React from 'react';
import {Container, Typography} from '@material-ui/core';
import {Add as AddIcon} from '@material-ui/icons';
import PhotoList from '../Components/PhotoList/PhotoList';
import FloatingActionButton from '../Components/FloatingActionButton/FloatingActionButton';

class ProgressPhotos extends React.Component {
    componentDidMount() {
        this.props.updateCurrentPage('Progress Photos');
    }
    render() {
        return (
            <Container maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom={true}>Progress Photos</Typography>
                <PhotoList history={this.props.history} data={this.props.data} store={this.props.store} />
                <FloatingActionButton to="/add-photo" color="primary" label="add" icon={<AddIcon />} />
            </Container>
        );
    }
}

export default ProgressPhotos;