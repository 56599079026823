import React from 'react';
import {List, Typography} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import LogItem from './LogItem';

class LogList extends React.Component {
    constructor(props) {
        super(props);
        this.dateUtils = new DateFnsUtils();
        this.dateUtils.dateFormat = "dd/mm/YYYY";
    }
    deleteItem(id) {
        this.props.store.deleteDay(id).then((success) => {
            if (!success) {
                alert('There was a problem deleting this day');
            }
        });
    }
    render() {
        let logItems = this.props.data.days.map((day) => {
            const niceDate = this.dateUtils.format(this.dateUtils.date(day.date), "iii, do LLLL yyyy");
            return (
                <LogItem key={day.id} id={day.id} date={niceDate} caloriesTotal={day.caloriesTotal} waterTotal={day.waterTotal} activityDuration={day.activityDuration} deleteItem={this.deleteItem.bind(this)} />
            );
        });
        if (Object.keys(this.props.data.days).length <= 0) {
            logItems = <Typography component="p" varient="body2">You have not logged any days</Typography>
        }
        return (
            <List>
                {logItems}
            </List>
        );
    }
}

export default LogList;