import $ from 'jquery';
import Constants from '../Constants';
import DateFnsUtils from '@date-io/date-fns';

class DaysStore {
    constructor() {
        this.data = {
            days: []
        };
        this.dateUtils = new DateFnsUtils();
        this.dateUtils.dateFormat = "dd/mm/YYYY";
        this.getData();
    }
    getData() {
        fetch(Constants.endpoints.data).then((response) => response.json()).then((data) => {
            /* let days = data.days;
            days.sort(function (a, b) {
                var key1 = new Date(a.date);
                var key2 = new Date(b.date);
            
                if (key1 < key2) {
                    return -1;
                } else if (key1 == key2) {
                    return 0;
                } else {
                    return 1;
                }
            }); */
            this.data = data;
            $(window).trigger(`DaysStore.${Constants.events.storeUpdated}`);
        });
    }
    getDay(id) {
        let days = this.data.days.filter((day) => (day.id === id));
        if (days.length <= 0) return false;
        return days[0];
    }
    updateDay(id, data) {
        let formData = new FormData();
        for (let key in data) {
            let value = data[key];
            if (typeof value === 'object' && value.constructor.name === 'Date') {
                value = this.dateUtils.format(new Date(value), "yyyy-LL-dd HH:mm:ss");
            }
            formData.append(key, value);
        };
        formData.append('id', id);
        return fetch(Constants.endpoints.updateDay, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: formData,
        }).then((response) => response.json()).then((data) => {
            //Get latest data
            this.getData();
            // Return success status
            return (data.status === 'ok');
        });
    }
    createDay(data) {
        let formData = new FormData();
        for (let key in data) {
            let value = data[key];
            if (typeof value === 'object' && value.constructor.name === 'Date') {
                value = this.dateUtils.format(new Date(value), "yyyy-LL-dd HH:mm:ss");
            }
            formData.append(key, value);
        };
        return fetch(Constants.endpoints.createDay, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: formData,
        }).then((response) => response.json()).then((data) => {
            //Get latest data
            this.getData();
            // Return success status
            return (data.status === 'ok');
        });
    }
    deleteDay(id) {
        let formData = new FormData();
        formData.append('id', id);
        return fetch(Constants.endpoints.deleteDay, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: formData,
        }).then((response) => response.json()).then((data) => {
            //Get latest data
            this.getData();
            // Return success status
            return (data.status === 'ok');
        });
    }
}

const daysStore = new DaysStore();

export default daysStore;