import React from 'react';
import {Link} from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {Container, Grid, Typography, FormControl, Button, Input, InputLabel, TextField} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import ObjectEquals from 'object-equals';

class Day extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            day: {
                date: new Date(),
                caloriesTotal: "",
                waterTotal: "",
                activityDuration: "",
                wakeTime: new Date('2020-01-01T07:00'),
                sleepTime: new Date('2020-01-01T22:00'),
                notes: ""
            }
        };
        if (this.props.data.days.length > 0 && props.match.params.id) {
            this.state.day = this.getDay(props.data.days, props.match.params.id);
        }
    }
    componentDidMount() {
        const pageTitle = (this.props.match.params.id ? 'View Day' : 'Add Day');
        this.props.updateCurrentPage(pageTitle);
    }
    componentDidUpdate(oldProps) {
        if (this.props.match.params.id) {
            const newDay = this.getDay(this.props.data.days, this.props.match.params.id);
            const oldDay = this.getDay(oldProps.data.days, oldProps.match.params.id);
            if (!ObjectEquals(newDay, oldDay)) {
                this.setState({
                    day: newDay
                });
            }
        }
    }
    getDay(days, id) {
        let matchingDays = days.filter((day) => (day.id === id));
        if (matchingDays.length <= 0) return false;
        return matchingDays[0];
    }
    handleDateChange(date) {
        let currentState = this.state;
        currentState.day.date = date;
        this.setState(currentState);
    }
    handleCalorieChange(e) {
        let currentState = this.state;
        currentState.day.calories = e.target.checked;
        this.setState(currentState);
    }
    handleWaterChange(e) {
        let currentState = this.state;
        currentState.day.water = e.target.checked;
        this.setState(currentState);
    }
    handleActivityChange(e) {
        let currentState = this.state;
        currentState.day.activity = e.target.checked;
        this.setState(currentState);
    }
    handleWakeChange(time) {
        let currentState = this.state;
        currentState.day.wakeTime = time;
        this.setState(currentState);
    }
    handleSleepChange(time) {
        let currentState = this.state;
        currentState.day.sleepTime = time;
        this.setState(currentState);
    }
    handleCalorieValueChange(e) {
        let currentState = this.state;
        currentState.day.caloriesTotal = parseInt(e.target.value, 10);
        this.setState(currentState);
    }
    handleWaterValueChange(e) {
        let currentState = this.state;
        currentState.day.waterTotal = parseInt(e.target.value, 10);
        this.setState(currentState);
    }
    handleActivityDurationChange(e) {
        let currentState = this.state;
        currentState.day.activityDuration = parseInt(e.target.value, 10);
        this.setState(currentState);
    }
    handleNotesChange(e) {
        let currentState = this.state;
        currentState.day.notes = e.target.value;
        this.setState(currentState);
    }
    handleSubmit(e) {
        e.preventDefault();
        let asyncTask;
        if (this.props.match.params.id) {
            asyncTask = this.props.store.updateDay(this.props.match.params.id, this.state.day);
        } else {
            asyncTask = this.props.store.createDay(this.state.day);
        }
        asyncTask.then((success) => {
            if (!success) {
                alert('There was a problem saving this day');
                return;
            }
            this.props.history.push('/');
        });
    }
    render() {
        const pageTitle = (this.props.match.params.id ? 'Update day' : 'Add new day');
        const cancelLabel = (this.props.match.params.id ? 'Close' : 'Cancel');
        let day = this.state.day;
        return (
            <Container maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom={true}>{pageTitle}</Typography>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" required={true} fullWidth={true}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="none"
                                        id="date"
                                        label="Date"
                                        format="dd/MM/yyyy"
                                        value={day.date}
                                        onChange={this.handleDateChange.bind(this)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" required={true} fullWidth={true}>
                                <InputLabel htmlFor="calories-value">Total calory intake</InputLabel>
                                <Input id="calories-value" type="number" value={day.caloriesTotal} onChange={this.handleCalorieValueChange.bind(this)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" required={true} fullWidth={true}>
                                <InputLabel htmlFor="water-value">How much water did you drink</InputLabel>
                                <Input id="water-value" type="number" value={day.waterTotal} onChange={this.handleWaterValueChange.bind(this)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" required={true} fullWidth={true}>
                                <InputLabel htmlFor="activity-duration">Duration of activity</InputLabel>
                                <Input id="activity-duration" type="number" value={day.activityDuration} onChange={this.handleActivityDurationChange.bind(this)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" required={true} fullWidth={true}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        margin="none"
                                        id="wake-time"
                                        label="What time did you wake up?"
                                        value={day.wakeTime}
                                        onChange={this.handleWakeChange.bind(this)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" required={true} fullWidth={true}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        margin="none"
                                        id="sleep-time"
                                        label="What time did you go to sleep?"
                                        value={day.sleepTime}
                                        onChange={this.handleSleepChange.bind(this)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl margin="dense" required={false} fullWidth={true}>
                                <TextField
                                    margin="none"
                                    id="notes"
                                    label="Notes"
                                    multiline
                                    rows={4}
                                    value={day.notes}
                                    onChange={this.handleNotesChange.bind(this)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} justify="flex-end">
                        <Grid item xs={3}>
                            <FormControl margin="normal" fullWidth={true}>
                                <Button variant="contained" color="secondary" component={Link} to="/">{cancelLabel}</Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl margin="normal" fullWidth={true}>
                                <Button variant="contained" color="primary" type="submit">Save</Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}

export default Day;